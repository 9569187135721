@import "./colors.scss";

html {
  background: var(--clr-bg); //$secondary;
  color: var(--clr-font); //$tertiary;
  font-size: 14px;
  line-height: 18px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.is-mobile {
  body {
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

*,
::before,
::after {
  box-sizing: border-box;
  font-family: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 0;
  padding: 0;
  //height: 100%;
  min-width: 320px;
  //max-width: 100vw;
  //overflow-x: hidden !important;
}

ul,
ol {
  margin: 0;
}
