@import "./basic.scss";

#root {
  //width: 100%;
  //height: 100%;
  //overflow: auto;
  //width: 100vw;
  --var-card-spacing: 8px;
  @media (min-width: 600px) {
    // xs > sm
    --var-card-spacing: 10px;
  }
  @media (min-width: 900px) {
    // xs > sm
    --var-card-spacing: 12px;
  }
  @media (min-width: 1200px) {
    // xs > sm
    --var-card-spacing: 14px;
  }
}

.app-logo {
  display: flex;
  --app-logo-mt: 8px;
  --app-logo-mb: 8px;
  margin: var(--app-logo-mt) 0 var(--app-logo-mb);
  img {
    margin: 0 auto;
  }
}

.MuiDrawer-root {
  .app-logo {
    height: 60px;
  }
}

img {
  display: block;
}

.App {
  max-width: 1500px;
  margin: 0 auto;
  --app-bar-height: 56px;
  @media (min-width: 600px) {
    // xs > sm
    --app-bar-height: 64px;
  }
  > .MuiBox-root {
    .app-logo {
      height: calc(
        var(--app-bar-height) - var(--app-logo-mt) - var(--app-logo-mb)
      );
    }
    .MuiToolbar-root {
      margin: 0 auto;
      width: 100%;
      @media (min-width: 1200px) {
        max-width: 1200px;
      }
    }
  }
  > .MuiContainer-root {
    padding-top: var(--app-bar-height);
  }
}

nav {
  background: var(--clr-bg) !important;
  color: var(--clr-font) !important;
}

/*
*
* Masonry Layout react-masonty-css documentation
*
*/
.my-masonry-grid {
  display: flex;
  //margin-left: 15%; /* gutter size offset */
  //max-width: 70%;
  width: auto;
  padding-top: 70px;
  --gutter-size-vert: 10px;
  --gutter-size-hori: calc(var(--gutter-size-vert));
}
.my-masonry-grid_column {
  padding-left: var(--gutter-size-vert);
  background-clip: padding-box;
  &:last-child {
    margin-right: var(--gutter-size-vert);
  }
}
/* Style your items */
.my-masonry-grid_column > div {
  margin-bottom: var(--gutter-size-hori);
  cursor: pointer;
}
.my-masonry-grid_column > div > img {
  width: 100%;
  //display: block;
  border-radius: 2px;
  position: relative;
  transition: all 0.3s ease;
  filter: brightness(80%);
}
.my-masonry-grid_column > div > img:hover {
  filter: brightness(100%);
  transform: scale(1.02);
  z-index: 50;
  box-shadow: 0px 0px 5px 2px rgb(48, 47, 47);
}
.center-masonry {
  background: rgb(233, 232, 232);
}
